@charset 'utf-8';

@use './styles/colors';

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// toastr
@import 'ngx-toastr/toastr';

// Settings (Custom)
@import 'styles/settings';

// Project helper classes
@import 'styles/helpers';

// Import Foundation components (custom modified in /styles/ folder)
@import 'styles/global';

// Project specific styling
@import 'styles/theme';

// Import Motion UI
// @import 'motion-ui/src/motion-ui';
// Angular material
@import 'styles/material';

// Foundation Core (Everything)
@import 'foundation-sites/scss/foundation';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-flex-classes;
