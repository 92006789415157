@import './settings';
@import './global';

h1 {
  font-weight: 700 !important;
}
p {
  font-weight: 400;
}

a {
  outline: none;
}

.is-active {
  font-weight: 700;
}

.button {
  font-weight: 600;
  letter-spacing: 0.19px;
  text-decoration: none;
  white-space: nowrap;
}

.rounded-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  border-radius: 25px !important;
  padding: 4px 24px;
  min-height: 36px;
  min-width: 100px;
  width: fit-content;
  color: white;
  position: relative;

  &.small {
    max-width: 150px;
  }
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-mdc-dialog-container {
  max-width: unset !important;
}

.cdk-overlay-container .rounded-corners {
  .mat-mdc-dialog-container {
    border-radius: $global-radius;
    overflow: visible;
    background-color: white;
    box-shadow: none;
    filter: drop-shadow(4px 6px 10px rgba(0, 0, 0, 0.2));
  }
}

.cdk-overlay-container .only-rounded-corners {
  .mat-mdc-dialog-container {
    border-radius: $global-radius;
  }
}

// No padding in zelo preview
.rounded-corners-preview {
  .mat-mdc-dialog-surface {
    border-radius: $global-radius;
    padding: 0 !important;
    position: relative;
    min-height: 50vh;
  }
}

/** Modal Classes */
.modal-container {
  padding: 2em !important;
  position: relative;
  min-height: 10em;
  border-radius: 1em;
  overflow: visible !important;
  .modal-close-button {
    position: absolute;
    top: -35px;
    right: -11px;
    width: 32px;
    height: 32px;
    z-index: 1;
    background: $white;
    border-radius: 50%;
    cursor: pointer;

    .modal-close-icon {
      width: 20px;
      cursor: inherit;
    }
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  height: 100% !important;
}

// Cdk Tables
.mat-sort-header-button {
  text-transform: uppercase !important;
}

// wysiswyg editor styling
.fr-box {
  margin-bottom: 1rem;
  border: var(--gray-300);
}

.fr-basic,
.fr-element {
  border: var(--gray-300);
}

// Sets the colour of selectors
::selection {
  color: $white;
  background: $primary-color;
}

::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary-color;
}

.mat-icon svg {
  height: 28.9px;
}

.dialog-backdrop {
  background: rgba(0, 0, 0, 0.8);
}

// Multi choice component

mat-radio-button {
  z-index: 2;

  label {
    width: 100% !important;
    color: white !important;
    font-size: 1rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-weight: 500;
    cursor: pointer;
  }

  .mat-mdc-focus-indicator {
    top: -2px;
    left: -2px;
  }

  .mdc-radio__background {
    top: -6px !important;
    left: -6px !important;

    height: 2rem !important;
    width: 2rem !important;

    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      background-color: white !important;
      border-color: $primary-color !important;
    }
  }
  .mdc-radio__background::before {
    background-color: transparent !important;
  }
}

.channel-radio-group {
  label {
    color: $black !important;
    margin-right: 10px;
    font-weight: 400;
  }
}

// Step Scale
.scale-radio-group {
  position: relative;
  z-index: 2;

  label {
    display: none !important;
  }

  @media screen and (min-width: 640px) {
    .scale-radio-group {
      label {
        font-size: 1.5rem;
        padding-left: 1rem;
      }
    }
  }
}

.mat-horizontal-stepper-wrapper > .mat-horizontal-stepper-header-container {
  display: none;
}

.mat-horizontal-stepper-wrapper > .mat-horizontal-content-container {
  padding: 0;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  font-family: $body-font-family;
}

@media screen and (max-width: 639px) {
  .step-response-content p {
    font-size: 16px;
  }
}

// NOTIFICATIONS (TOAST)

.zelo-toast {
  border-radius: $global-radius;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 15px;

  background-image: none !important;

  @media screen and (max-width: 640px) {
    width: 90%;
  }
}

.zelo-toast-success {
  background-color: #88f0b2 !important;
  color: var(--purple-500) !important;
}

.zelo-toast-alert {
  background-color: #cc4b37 !important;
  color: white !important;
}

.zelo-toast-warning {
  background-color: #ffae00 !important;
}

.toast-container {
  padding-bottom: 80px;
}

// Admin dashboard - tighter spacing between cells
.step-content {
  .grid-padding-x {
    padding-left: 1rem;
    padding-right: 1rem;
    .cell {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.step-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  label {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
  }

  input {
    margin-bottom: 0 !important;
    margin-left: 16px !important;
  }
}

.step-actions-container {
  display: flex;
  flex-direction: row;
  width: 38%;
  margin-left: 2.5%;
  justify-content: space-evenly;
}

.step-action-divider {
  content: '';
  height: 1px;
  width: 35px;
  background: #cac5e1;
  align-self: center;
  transform: rotate(90deg);
  pointer-events: none;
}

.step-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;

  height: 32px;
  padding: 0 10px;

  p {
    font-size: 14px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 0 !important;
    margin-right: 6px !important;
  }

  span {
    color: $primary-color;
    transform: scale(2.2);
    margin-left: 6px;
  }
}

.step-action:hover {
  background-color: #dfdcf0;
  border-radius: 20px;
}

.mdc-tooltip__surface {
  font-family: $body-font-family;
  font-size: 14px;
  color: var(--gray-700) !important;
  padding: 8px;
  background-color: var(--gray-100) !important;
  border: 1px solid var(--gray-200);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 300px;
  width: 100%;

  &.icon-right > zlo-icon {
    position: absolute;
    right: 3%;
  }
}

.zelo-text-success {
  color: $success-color;
}

/** Froala Editor */
.fr-disabled {
  background: #e6e6e6 !important;
  pointer-events: none;
}

.fr-box.fr-basic .fr-element.fr-view {
  // default font size
  font-size: 16px;
}

/** Froala Editor  END*/

.fr-popup.fr-active {
  z-index: 1000 !important;
}

.display-responses {
  padding: 0;
  text-align: right;
  float: right;
  a {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2878, 63, 158%29'></polygon></svg>");
    padding: 0 15px 0 0;
    height: 15px;
    border: none;
    outline: none;
    color: #4e3f9e;
    font-size: 14px;
    line-height: 4px;
    text-decoration: underline;
    opacity: 0.8;
    direction: rtl;
    float: right;
    background-position: 100% 0px;
    background-repeat: no-repeat;
    background-size: 10px;
  }
}
.responses-table {
  .no-table-widget {
    display: block;
    margin-bottom: 20px;
  }
  .responses-data-table {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .no-chart {
    font-size: 20px;
    padding: 30px;
    font-weight: bold;
    text-align: center;
  }
  .widget-select-container {
    p {
      padding: 0 15px 0 0;
      height: 16px;
      border: none;
      outline: none;
      color: #4e3f9e;
      font-size: 14px;
      line-height: 5px;
      text-decoration: underline;
      opacity: 0.8;
      direction: rtl;
      float: right;
      background-position: 100% 0px;
      background-repeat: no-repeat;
      background-size: 10px;
    }
  }
  .activity-grid {
    .title {
      line-height: 22px;
      color: #5c5c5c;
      font-weight: bold;
    }
  }
  .activities-legend {
    line-height: 22px;
    color: #5c5c5c;
    font-size: 14px;
    padding: 0;
    margin-left: 28px;
  }
}

.mat-mdc-paginator-page-size,
.mat-mdc-paginator-range-label {
  color: var(--gray-500) !important;
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: unset !important;
}

// Material Checkbox

.mat-mdc-checkbox-label {
  font-family: $body-font-family;
}

.mdc-checkbox__background {
  display: block !important;
  top: 4px !important;
  left: 4px !important;

  border: 1px solid #ccc !important;
  border-width: 1px !important;
  border-radius: 4px !important;
  height: 2rem !important;
  width: 2rem !important;
  background-color: white !important;
  padding: 4px !important;

  .mdc-checkbox__checkmark {
    transform: scale(0.75);
    path {
      stroke: $primary-color !important;
    }
  }
}

.step-content-section {
  mat-checkbox {
    label {
      color: white !important;
    }
  }
}

.mdc-checkbox__ripple {
  display: none !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragdroplist.cdk-drop-list-dragging
  .step-container:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.mat-option,
.mat-mdc-select-panel-above {
  width: fit-content !important;
  min-width: 128px;
  max-width: 480px !important;
}
