/*stylelint-disable*/
@use 'settings' as settings;

.gray-500 {
  color: var(--gray-500) !important;
}

.gray-600 {
  color: var(--gray-600) !important;
}

.gray-900 {
  color: var(--gray-900) !important;
}

.purple-500 {
  color: var(--purple-500) !important;
}

.bg-purple-050 {
  background-color: var(--purple-050) !important;
}

.bg-gray-050 {
  background-color: var(--gray-050) !important;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.indigo-800 {
  color: var(--indigo-800) !important;
}

// Typography

.heading {
  &.h0 {
    font-size: 2.5rem;
    font-weight: 800;
    color: var(--gray-900);
  }

  &.h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--gray-700);
  }

  &.h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--gray-700);
  }

  &.h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--gray-700);
  }

  &.h4 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--gray-700);
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.primary-color {
    color: var(--purple-500);
  }

  &.nowrap {
    white-space: nowrap;
  }

  @media screen and (max-width: 480px) {
    &.h0 {
      font-size: 2.5rem;
      line-height: 1.25;
      font-weight: 800;
      color: var(--gray-900);
    }
  }

  &.center {
    text-align: center;
  }
}

.body-text {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-700);
  margin: 0;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: var(--gray-700);
  }

  &.zelo-title {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--gray-700);
  }

  &.regular {
    font-weight: 400;
  }

  &.smaller {
    font-size: 0.875rem;
    color: var(--gray-600);
  }

  &.large {
    font-size: 1.125rem;
  }

  &.quote {
    font-weight: 400;
    font-style: italic;
  }

  &.light {
    font-weight: 300;
    color: var(--gray-400);
  }

  &.italic {
    font-style: italic;
  }

  &.font-weight-600 {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }

  &.center {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    .body.text {
      font-size: 0, 875rem;
    }

    p {
      font-size: 0.875rem;
    }

    &.regular {
      font-size: 0.875rem;
    }

    &.large {
      font-size: 1rem;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.labels {
  &.list-section {
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--gray-500);
    text-transform: uppercase;
  }

  &.card-title {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
    color: var(--purple-500);
    font-weight: 600;
    text-transform: uppercase;
  }

  &.form {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--gray-500);
  }
}

.link {
  font-weight: 500;

  &.standard {
    color: var(--gray-700);

    &:hover {
      color: var(--purple-500);
      text-decoration: underline;
    }
  }

  &.purple {
    color: var(--purple-500);
    &:hover {
      text-decoration: underline;
    }
  }
}

.weight-500 {
  font-weight: 500;
}

.split-button {
  height: 40px;
  cursor: pointer;

  &.left {
    min-width: 96px;
    padding: 0 16px;
    border-radius: 6px 0 0 6px;
  }

  &.right {
    padding: 0 8px;
    border-radius: 0 6px 6px 0;
    border-left: 0;
  }
}

button {
  padding: 10px 20px 10px 20px;
  &.outline {
    background-color: transparent;
    color: settings.$gray;
    border: 2px solid;
    font-weight: 500;

    &:hover {
      color: white !important;
    }
  }

  &.faded-grey {
    color: #5c5c5c59;
    border-color: #5c5c5c59;

    &:hover {
      background-color: red;
      border-color: red;
    }
  }

  &.light {
    color: var(--gray-700);
    background-color: var(--gray-050);
    border: 1px solid var(--gray-300);
    font-weight: 400;

    zlo-icon {
      color: var(--gray-500);
    }

    &:hover {
      color: var(--gray-700);
      background-color: var(--gray-100);
    }
  }

  &.light-purple {
    color: var(--purple-500);
    background-color: var(--purple-050);
    border: 1px solid var(--purple-100);
    font-weight: 400;

    zlo-icon {
      color: var(--purple-500);
    }

    &:hover {
      color: var(--purple-500);
      background-color: var(--purple-100);
    }
  }

  &.text-only {
    background-color: none;
    color: var(--purple-500);
    font-size: 600;
    padding: 12px 16px;
    cursor: pointer;
    transition-duration: 250ms;

    &:hover,
    &:focus {
      background-color: var(--purple-500);
      border: 1px solid var(--purple-500);
      color: white;
    }
  }

  &.delete-text {
    background-color: none;
    color: var(--red-600);
    padding: 12px 16px;
    cursor: pointer;
  }

  &.delete {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-300);
    color: var(--red-600);
    font-size: 600;
    padding: 12px 16px;
    cursor: pointer;
    transition-duration: 250ms;

    &:hover,
    &:focus {
      background-color: var(--red-600);
      border: 1px solid var(--red-600);
      color: white;
    }
  }
}

.input {
  &.standard {
    padding: 0.75rem;
    border: 1px solid var(--gray-300);
    border-radius: 6px;
    height: 48px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;
  }

  &.textarea {
    padding: 0.75rem;
    border: 1px solid var(--gray-300);
    border-radius: 6px;
    height: 96px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;
    resize: vertical;
  }
}

.link {
  color: #333;
  cursor: pointer;
}

.circular-button {
  all: unset;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &.plus::before {
    content: '+';
    position: absolute;
    top: 1px;
    left: 4px;
    font-size: 20px;
  }

  &.close::before {
    content: '⨯';
    position: absolute;
    top: -2px;
    left: 5px;
    font-size: 23px;
  }

  &.grey {
    background: #cac5e1;

    &:active {
      background: #d9d5e7;
    }
  }

  &.primary {
    background: #4e3f9e;
    color: white;

    &:active {
      background: #6a5fa8;
    }
  }
}

.shadow {
  &.regular {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  &.medium {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }
}

section {
  &.divider {
    border-bottom: 1px solid var(--gray-200);
  }
}

// Dropdown
.dropdown {
  &.parrent {
    position: relative;
    float: right;
  }

  &.child {
    position: absolute;
    right: 0;
    z-index: 5;
    min-width: 192px;
    padding: 0; // This padding works together with 'link' + 'dropdown' from _helpers.scss
    z-index: 1000;
  }

  &.dropdown-link {
    color: var(--gray-700);
    font-weight: 500;
    padding: 12px 16px;
    width: 100%;
    border-bottom: 1px solid var(--gray-200);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      color: var(--purple-500);
      background-color: var(--gray-050);
    }
  }
}

// Global sidenav styling

.side-menu-container {
  height: 100%;
  max-width: 256px;
  min-width: 256px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: settings.$LAYER-3;
  position: sticky;
  top: 0;
}

.side-menu-content {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  /** HEADER */
  zlo-icon {
    margin-right: 8px;
    color: var(--gray-400);
  }

  a {
    display: block;
    padding: 0.375rem 0;
    color: var(--gray-700);
    font-weight: 500;
    font-size: 16px;
    &:hover {
      color: #4e3f9e;
    }
  }

  > section {
    margin-bottom: 2rem;
  }

  .is-active {
    background-color: var(--gray-100);
    font-weight: 600;
    color: #4e3f9e;
    text-decoration: none;
    margin-left: -32px;
    padding-left: 26px;
    border-left: solid 6px settings.$primary-color;
    border-radius: 0 6px 6px 0;

    zlo-icon {
      color: settings.$primary-color;
    }
  }

  ol {
    margin: 0;
    li {
      list-style: none;
    }
  }
}

/*stylelint-enable*/

/* ---------- FLEX HELPERS ---------- */
.display-flex {
  display: flex;

  &.align-center {
    align-items: center;
  }
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &.bottom {
    bottom: 0px;
  }

  &.fixed {
    position: fixed;
  }

  &.relative {
    position: relative;
  }

  &.grow {
    flex-grow: 1;
  }

  &.narrow {
    flex: 1;
  }

  &.half {
    width: 50%;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.nowrap {
    flex-wrap: nowrap !important;
  }

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }

  &.row {
    flex-direction: row;
  }

  &.align-center {
    align-items: center;
  }

  &.align-top {
    align-items: baseline;
  }

  &.align-bottom {
    align-items: bottom;
  }

  &.vertical-center {
    align-self: center !important;
  }

  &.flex-start {
    align-items: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.self-center {
    align-self: center;
  }

  &.fluid {
    margin: 0 -1rem;
    > * {
      margin: 0 1rem;
    }
  }

  &.two-columns {
    margin: 0 -1rem;

    > * {
      align-self: flex-start;
      width: 50%;
      min-width: auto;
      padding: 0 1rem;
      > * {
        width: 100%;
      }
    }
  }

  &.gap-m {
    gap: 6px 12px;
  }

  &.gap-4 {
    gap: 4px;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-16 {
    gap: 16px;
  }

  &.gap-24 {
    gap: 24px;
  }

  &.gap-32 {
    gap: 32px;
  }
}

/* ---------- MARGINS ---------- */
.marginless {
  margin: 0 !important;
}

.margin-a {
  margin: auto;
}

.margin-s {
  margin: 8px;
}
.margin-m {
  margin: 16px !important;
}
.margin-l {
  margin: 32px;
}

.margin-t0 {
  margin-top: 0 !important;
}
.margin-ts {
  margin-top: 8px;
}
.margin-tm {
  margin-top: 16px !important;
}
.margin-tl {
  margin-top: 32px !important;
}

.margin-b0 {
  margin-bottom: 0 !important;
}
.margin-bs {
  margin-bottom: 8px;
}
.margin-bm {
  margin-bottom: 16px;
}
.margin-bl {
  margin-bottom: 32px;
}

.margin-r0 {
  margin-right: 0 !important;
}

.margin-rs {
  margin-right: 8px;
}

.margin-rm {
  margin-right: 16px;
}

.margin-rl {
  margin-right: 32px;
}

.margin-l0 {
  margin-left: 0 !important;
}

.margin-ls {
  margin-left: 8px !important;
}

.margin-lm {
  margin-left: 16px !important;
}

.margin-ll {
  margin-left: 32px;
}

.margin-la {
  margin-left: auto !important;
}

.margin-ra {
  margin-right: auto;
}

.margin-ta {
  margin-top: auto !important;
}

.margin-ba {
  margin-bottom: auto;
}

/** Colors */
.color-white {
  color: settings.$white;
}

.color-white-shade {
  color: settings.$white-shade !important;
}

.color-black {
  color: settings.$black;
}

.color-red {
  color: red;
}

.color-primary {
  color: settings.$primary-color !important;
}

.color-disabled {
  color: settings.$not-found-grey;
  border-color: settings.$not-found-dark;
}

.color-success {
  color: #88f0b2;
}

.bg-color-success {
  background-color: #88f0b2 !important;
}

.bg-color-disabled {
  background-color: #7c7c7c !important;
}

.bg-color-white {
  background-color: settings.$white !important;
}

.bg-color-baby-blue {
  background-color: #ccffff !important;
}

.bg-color-warning-yellow {
  background-color: #fffcc9 !important;
}

/* ---------- PADDINGS ---------- */
.paddingless {
  padding: 0 !important;
}

.padding-s {
  padding: 8px;
}

.padding-m {
  padding: 16px;
}

.padding-l {
  padding: 32px;
}

.padding-xl {
  padding: 42px !important;
}

.padding-ts {
  padding-top: 8px;
}

.padding-tm {
  padding-top: 16px;
}

.padding-tl {
  padding-top: 32px;
}

.padding-txl {
  padding-top: 42px;
}

.padding-r0 {
  padding-right: 0;
}

.padding-rxs {
  padding-right: 4px;
}

.padding-rs {
  padding-right: 8px;
}

.padding-rm {
  padding-right: 16px;
}

.padding-rl {
  padding-right: 32px;
}

.padding-rxl {
  padding-right: 42px;
}

.padding-bxs {
  padding-bottom: 4px;
}

.padding-bs {
  padding-bottom: 8px;
}

.padding-bm {
  padding-bottom: 16px;
}

.padding-bl {
  padding-bottom: 32px;
}

.padding-bxl {
  padding-bottom: 42px;
}

.padding-l0 {
  padding-left: 0 !important;
}

.padding-ls {
  padding-left: 8px;
}

.padding-lm {
  padding-left: 16px;
}

.padding-ll {
  padding-left: 32px;
}

.padding-lxl {
  padding-left: 42px;
}

/* ---------- FONTS ---------- */
.fw-light {
  font-weight: 500 !important;
}
.fw-medium {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fs-large {
  font-size: 24px;
}
.bolder {
  font-weight: bolder;
}
.smaller {
  font-size: smaller;
}
.larger {
  font-size: larger;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

/* ---------- POSITIONS ---------- */
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important ;

  &.right {
    right: 0;
  }
}
/* stylelint-disable */

/* ---------- MISC ---------- */
p {
  &.disabled {
    color: settings.$not-found-grey;
  }
}
/* stylelint-enable */

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.underline {
  text-decoration: underline;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.full-width {
  width: 100% !important;
}

.max-width-480 {
  max-width: 480px;
}

.max-width-1440 {
  max-width: 1440px;
}

.hidden {
  visibility: hidden !important;
}

.auto-width {
  width: auto !important;
}

.fixed-width-150 {
  width: 150px !important;
}

.fixed-width-192 {
  width: 192px !important;
}

.fixed-width-250 {
  width: 250px !important;
}

.fixed-height-40 {
  height: 40px !important;
}

.fixed-height-48 {
  height: 48px !important;
}

.fixed-height-64 {
  height: 64px !important;
}

.fixed-height-150 {
  height: 150px !important;
}

.fixed-height-250 {
  height: 250px !important;
}

.full-height {
  height: 100%;
}

.min-height-48 {
  min-height: 48px;
}

.bg-disabled {
  background-color: settings.$not-found-white;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f2f2f2' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-white {
  background-color: white;
}

.shadow-1 {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important;
}

.text-align-right {
  text-align: right !important;
}

.divider-line {
  border-top: 1px solid settings.$medium-gray;
  display: flex;
  width: 100%;

  &.small {
    margin: 8px auto 8px auto;
  }

  &.medium {
    margin: 16px auto 16px auto;
  }

  &.large {
    margin: 32px auto 32px auto;
  }
}

.border {
  &.dashed {
    border: 1px dashed;
  }

  &.solid {
    border: 1px solid;
  }

  &.gray {
    border-color: settings.$light-gray;
  }

  &.rounded {
    border-radius: 6px;
  }
}

.chip {
  padding: 4px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;

  &.square {
    border-radius: 6px;
  }

  &.green {
    background-color: var(--green-100);
    color: var(--green-800);
  }

  &.yellow {
    background-color: var(--yellow-100);
    color: var(--yellow-800);
  }

  &.purple {
    background-color: var(--purple-100);
    color: var(--purple-800);
  }

  &.gray {
    background-color: var(--gray-200);
    color: var(--gray-200);
  }

  &.blue {
    background-color: var(--blue-100);
    color: var(--blue-800);
  }

  &.indigo {
    background-color: var(--indigo-100);
    color: var(--indigo-800);
  }

  &.fuchsia {
    background-color: var(--fuchsia-100);
    color: var(--fuchsia-800);
  }
}

.info-chip {
  padding: 0px 12px;
  font-weight: 400;
  font-size: 1rem;
  color: var(--purples-700);
  background-color: var(--purple-050);
  border-radius: 20px;

  &.green {
    color: var(--green-700);
    background-color: var(--greed-050);
  }

  &.red {
    color: var(--red-700);
    background-color: var(--red-050);
  }
}

.card {
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 24px;
  // border: 1px solid var(--gray-200);

  .card-footer {
    margin: 0 0 -24px -24px;
    width: calc(100% + 48px);
    padding: 24px;
    border-radius: 0 0 6px 6px;
    background-color: var(--gray-100);
  }
}

.toast-progress {
  background-color: settings.$white;
  opacity: 0.8;
  margin-bottom: settings.$global-radius;
}

.toast-container.toast-top-center .ngx-toastr {
  color: white;
  width: 450px;
  border-radius: settings.$global-radius;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;

  &.zelo-toast-alert {
    background-color: var(--red-900);
    background-position-x: calc(2 * #{settings.$global-radius});
    background-position-y: calc(3 * #{settings.$global-radius});
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");

    a {
      background-color: var(--red-050);
      color: var(--gray-900);
      text-decoration: none;
    }
  }

  &.zelo-toast-success {
    background-color: var(--green-900);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  }

  &.zelo-toast-warning {
    background-color: var(--purple-900);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  }
}

.avatar-img {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}
.display-block {
  display: block;
}

.rotate-180 {
  transform: rotate(180deg);
}

.master-page-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: flex-start;
}

.z-100 {
  z-index: 100;
}

.error {
  color: var(--red-600);
}
