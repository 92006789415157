:root {
  --purple-050: #f2f1fe;
  --purple-100: #e1dcfd;
  --purple-300: #bbb1f6;
  --purple-500: #4e3f9e;
  --purple-600: #4b3d97;
  --purple-700: #3c307c;
  --purple-900: #1c1157;

  --indigo-050: #f0f5ff;
  --indigo-100: #e5edff;
  --indigo-200: #cddbfe;
  --indigo-300: #b4c6fc;
  --indigo-400: #8da2fb;
  --indigo-500: #6875f5;
  --indigo-600: #5850ec;
  --indigo-700: #5145cd;
  --indigo-800: #42389d;
  --indigo-900: #362f78;

  --fuchsia-050: #fdf4ff;
  --fuchsia-100: #fae8ff;
  --fuchsia-200: #f5d0fe;
  --fuchsia-300: #f0abfc;
  --fuchsia-400: #e879f9;
  --fuchsia-500: #d946ef;
  --fuchsia-600: #c026d3;
  --fuchsia-700: #a21caf;
  --fuchsia-800: #86198f;
  --fuchsia-900: #701a75;

  --red-050: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --green-050: #f3faf7;
  --green-100: #def7ec;
  --green-200: #bcf0da;
  --green-300: #84e1bc;
  --green-400: #31c48d;
  --green-500: #0e9f6e;
  --green-600: #057a55;
  --green-700: #046c4e;
  --green-800: #03543f;
  --green-900: #014737;

  --blue-050: #f0f9ff;
  --blue-100: #e0f2fe;
  --blue-200: #bae6fd;
  --blue-300: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;

  --yellow-050: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;

  --gray-050: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
}
