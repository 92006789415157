//  Foundation for Sites Settings
//  -----------------------------

@import 'foundation-sites/scss/util/util';
// 1. Global
// ---------
$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$light-gray: #e6e6e6;
$medium-gray: #cbd5e1;
$dark-gray: #8a8a8a;
$gray: #5c5c5c;
$black: #333;
$white: #fff;
$white-shade: #f4f3f8;
$light-purple: #f9f7ff;
$light-blue: #c9eef9;
$not-found-white: #effff9;
$not-found-grey: #7c7c7c;
$not-found-dark: #333333;
$background-gray: #f9fafb;
$foundation-palette: (
  primary: #4e3f9e,
  secondary: #767676,
  success: #88f0b2,
  warning: #ffae00,
  alert: #e14457,
  cta: #88f0b2,
  white: $white
);
$body-background: $background-gray;
$body-font-color: $black;
// $body-font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Verdana, Arial,
//   sans-serif;
$body-font-family: 'Open Sans', sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 8px;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
@include add-foundation-colors;
// 2. Breakpoints
// --------------
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1025px,
  xlarge: 1200px,
  xxlarge: 1440px
);

$content-max-width: 1440px;

$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge);
// 3. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;
// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-styles: (
  small: (
    'h1': (
      'font-size': 20
    ),
    'h2': (
      'font-size': 20
    ),
    'h3': (
      'font-size': 19
    ),
    'h4': (
      'font-size': 18
    ),
    'h5': (
      'font-size': 17
    ),
    'h6': (
      'font-size': 16
    )
  ),
  medium: (
    'h1': (
      'font-size': 32
    ),
    'h2': (
      'font-size': 24
    ),
    'h3': (
      'font-size': 31
    ),
    'h4': (
      'font-size': 25
    ),
    'h5': (
      'font-size': 20
    ),
    'h6': (
      'font-size': 16
    )
  )
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$cite-pseudo-content: '\2014 \0020';
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;
// 5. Typography Helpers
// ---------------------
$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;
// 6. Abide
// --------
$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: get-color(alert);
$form-label-color-invalid: get-color(alert);
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;
// 11. Button
// ----------
$button-font-family: inherit;
$button-padding: 0.7em 1em;
$button-margin: 0 0 0 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $primary-color;
$button-radius: $global-radius;
$button-hollow-border-width: 1px;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  large: 1.25rem
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;
// 15. Close Button
// ----------------
$closebutton-position: right top;
$closebutton-offset-horizontal: (
  small: 0.66rem,
  medium: 1rem
);
$closebutton-offset-vertical: (
  small: 0.33em,
  medium: 0.5rem
);
$closebutton-size: (
  small: 1.5em,
  medium: 2em
);
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;
// 19. Flexbox Utilities
// ---------------------
$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;
// 20. Forms
// ---------
$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: $black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: $medium-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-padding: $form-spacing * 0.5;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;
// 56. Xy Grid
// -----------
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
  small: 20px,
  medium: 30px,
  large: 64px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: (
  small: 20px,
  medium: 30px
);
$grid-container-max: $global-width;
$xy-block-grid-max: 8;
// 57. Zelo specific
// -----------------

$background-opacity: 0.8;
$zelo-background-gradient: radial-gradient(
    circle at top left,
    transparentize(#4e3f9e, $background-opacity) 0%,
    transparentize(#80dff7, $background-opacity) 44.43%,
    transparentize(#08f0af, $background-opacity) 61.69%,
    transparentize(#dfffc9, $background-opacity) 100%
  ),
  linear-gradient(
    135deg,
    transparentize(#e1ffed, $background-opacity) 0%,
    transparentize(#ddf8ff, $background-opacity) 37.56%,
    transparentize(#f1fbea, $background-opacity) 100%
  );
$zelo-radius: 16px;
$step-background: #f4f3f8;
$step-border-radius: 6px;
$step-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
$info-step-background: #f4f4f4;
$chooser-step-border: 2px dashed $primary-color;
// LAYERS
$LAYER-1: 100;
$LAYER-2: 200;
$LAYER-3: 300;
$LAYER-4: 400;
$LAYER-5: 500;
$LAYER-20: 2000;
